import { FC } from 'react';
import IconProps from './IconProps';

const LocationIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg
      onClick={onClick}
      className={`inline ${cursor} ${className}`}
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="15" cy="15" r="15" fill="#2B2B2B" />
      <path
        d="M15.3125 14.6875C14.582 14.6875 13.9844 14.0969 13.9844 13.375C13.9844 12.6531 14.582 12.0625 15.3125 12.0625C16.043 12.0625 16.6406 12.6531 16.6406 13.375C16.6406 14.0969 16.043 14.6875 15.3125 14.6875ZM19.2969 13.5062C19.2969 11.1241 17.5371 9.4375 15.3125 9.4375C13.0879 9.4375 11.3281 11.1241 11.3281 13.5062C11.3281 15.8884 13.4152 19.9375 15.3125 19.9375C17.2098 19.9375 19.2969 15.8884 19.2969 13.5062ZM15.3125 8.125C18.1016 8.125 20.625 10.2381 20.625 13.5062C20.625 16 18.3482 21.25 15.3125 21.25C12.2768 21.25 10 16 10 13.5062C10 10.2381 12.5234 8.125 15.3125 8.125Z"
        fill="white"
      />
    </svg>
  );
};

export default LocationIcon;
